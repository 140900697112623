import Checkbox from "@/Components/Checkbox";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";
import { setLoader } from "@/Hooks/Functions";
import AuthenticationLayout from "@/Layouts/AuthenticationLayout";
import CameraBox from "@/Modules/CameraBox";
import { Icon } from "@iconify/react";
import { Head, Link, useForm } from "@inertiajs/react";
import { useEffect } from "react";

export default function Login({ status, canResetPassword }) {
    const params = new URLSearchParams(window.location.search);
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
        redirect_uri: params.get('redirect_uri') || null
    });

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();
        post(route("login"), { onStart: () => setLoader(true, 'Authenticating....'), onFinish: () => setLoader(false) });
    };

    return (
        <AuthenticationLayout color="#47c1bf">
            <Head title="Log in" />
            <CameraBox className="flex flex-col justify-center p-4 max-w-[500px] max-h-[60vh] items-center">
                <div className="text-white flex flex-col justify-center items-center p-4">
                    {status && (
                        <div className="mb-4 font-medium text-sm text-green-600">
                            {status}
                        </div>
                    )}
                    <form onSubmit={submit}>
                        <div className="w-full flex flex-col justify-center items-center p-4">
                            Log In
                        </div>
                        <div>
                            <InputLabel
                                className="text-white"
                                htmlFor="email"
                                value="Email"
                            />

                            <Input
                                id="email"
                                type="email"
                                name="email"
                                value={data.email}
                                className="mt-1 block w-full"
                                autoComplete="username"
                                isFocused={true}
                                onChange={(e) =>
                                    setData("email", e.target.value)
                                }
                            />

                            <InputError
                                message={errors.email}
                                className="mt-2"
                            />
                        </div>

                        <div className="mt-4">
                            <InputLabel
                                className="text-white"
                                htmlFor="password"
                                value="Password"
                            />

                            <Input
                                id="password"
                                type="password"
                                name="password"
                                value={data.password}
                                className="mt-1 block w-full"
                                autoComplete="current-password"
                                onChange={(e) =>
                                    setData("password", e.target.value)
                                }
                            />

                            <InputError
                                message={errors.password}
                                className="mt-2"
                            />
                        </div>

                        <div className="block mt-4">
                            <label className="flex items-center">
                                <Checkbox
                                    name="remember"
                                    checked={data.remember}
                                    onChange={(e) =>
                                        setData("remember", e.target.checked)
                                    }
                                />
                                <span className="ms-2 text-sm text-gray-50">
                                    Remember me
                                </span>
                            </label>
                        </div>

                        <div className="flex items-center flex-col gap-4 justify-end mt-4">
                            {canResetPassword && (
                                <Link
                                    href={route("password.request")}
                                    className="hover:underline text-sm text-gray-50 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Forgot your password?
                                </Link>
                            )}

                            <Button variant="theme"
                                className="px-5 py-2 w-full"
                                disabled={processing}
                            >
                                Log in
                            </Button>

                            <Link
                                href={route("register")}
                                className="hover:underline mt-8 text-sm text-gray-50 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Dont have account yet? Register
                            </Link>
                        </div>

                    </form>
                    <div className="flex md:flex-row flex-col items-center  my-4 w-full gap-4 flex-nowrap">
                        <a href={route('socialite-redirect', { provider: 'google' })}>
                            <Button size="xs" as="button" variant="outline">
                                <Icon icon="mdi:google" height={20} /> Continue with Google
                            </Button>
                        </a>
                        <a href={route('socialite-redirect', { provider: 'github' })}>
                            <Button size="xs" as="button" variant="outline">
                                <Icon icon="mdi:github" height={20} /> Continue with Github
                            </Button>
                        </a>
                    </div>
                </div>
            </CameraBox>
        </AuthenticationLayout>
    );
}
